const turntable = document.getElementById('turntable')
const record = document.getElementById('record')
const coverElm = document.getElementById('cover');
const player = document.getElementById('player')
const volume = document.getElementById('volume')
const arm = document.getElementById('arm')
const selectBox = document.getElementById('select-box')


import albums from './albums';

console.log(albums)

albums.map(album => {
  let group = document.createElement('optgroup');
  group.label = album.name;
  album.songs.map(song => {
    let o = document.createElement('option')
    o.value = JSON.stringify({ cover: album.cover, song: song.url })
    o.innerText = song.name
    group.appendChild(o)
  })
  selectBox.appendChild(group)
})
selectBox.addEventListener('change', () => {
  player.pause()
  let { song, cover } = JSON.parse(selectBox.value)
  coverElm.style.backgroundImage = `url(${cover})`
  player.src = song
})




player.addEventListener('pause', () => {
  turntable.classList.add('paused')
})
player.addEventListener('play', () => {
  turntable.classList.remove('paused')
})

const mousemove = () => player.volume = volume.value / 100

volume.addEventListener('mousedown', () => {
  volume.addEventListener('mousemove', mousemove)
})

volume.addEventListener('mouseup', () => {
  volume.removeEventListener('mousemove', mousemove)
})

player.addEventListener('timeupdate', () => {
  let min = 25;
  let max = 44;

  let range = max - min;
  let percent = player.currentTime / player.duration;
  let deg = (percent * range) + min;

  arm.style.transform = `rotate(-${deg}deg)`


  if (player.currentTime === player.duration) {
    newSong()
    player.play()
  }
})

record.addEventListener('click', () => {
  turntable.classList.contains('paused') ? player.play() : player.pause()
})